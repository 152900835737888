<template>
  <v-card flat>
    <v-layout flat>
      <main-loader :loading="isLoading" />
      <TitleBar :title="headTitle" :icon="headIcon"></TitleBar>
      <LeftNavBar v-if="token" :menuItems="menuItems" :defaultAvatar="AvatarLogo"></LeftNavBar>
      <v-main flat style="min-height: 732px" class="app-main">
        <router-view />
      </v-main>
    </v-layout>
  </v-card>
</template>

<script setup>
import { ref, onBeforeMount, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/services/auth/store'
import { useGlobalStore } from '@/store'

import useHook from '@/services/auth/hooks'

import LeftNavBar from './components/navigation/LeftNavBar.vue'
import TitleBar from './components/navigation/TitleBar.vue'
import MainLoader from './components/html/MainLoader.vue'

import menuItems from './menus'
const fullURL = window.location.href;
const url = new URL(fullURL);
const protocol = url.protocol;
const host = url.host;

const fullHost = protocol + '//' + host;

const AvatarLogo =  fullHost + '/assets/avatars/defaultAvatar.svg'


const $route = useRoute()
const $router = useRouter()

const headTitle = ref('')
const headIcon = ref('')

const { token } = storeToRefs(useAuthStore())
const { isLoading } = storeToRefs(useGlobalStore())
// const { onStart } = useGlobalStore()
const { onLoad } = useHook()


watch(token, () => {
  if (!token.value) {
    $router.push({ name: 'auth:login' })
    return true
  }
  if (['auth:login', 'auth:register'].includes($route.name)) {
    const routeName = JSON.parse(window.localStorage.getItem('oz-router'))
    // console.log("routeName in watch App", routeName)
    $router.push(routeName ? routeName : { name: 'auth:home' })

  }

  // onStart()
})

watch($route, route => {
  const { name } = route
  const activeRoute = Object.values(menuItems).find(tt => tt.value == name)

  if (activeRoute) {
    headTitle.value = activeRoute.title
    headIcon.value = activeRoute.icon
    return true
  }

  headTitle.value = ''
  headIcon.value = ''
})

onBeforeMount(() => {

  window.addEventListener('beforeunload', () => {
    window.localStorage.removeItem('oz-router')
    if (!['auth:login', 'auth:register'].includes($route.name)) {
      let routeName = { name: $route.name }
      // console.log(Object.keys($route.params).length)
      // console.log("window.addEventListener('beforeunload'", Object.keys($route.params))

      if (Object.keys($route.params).length)
        routeName = { name: $route.name, params: { uuid: $route.params.uuid } }

      window.localStorage.setItem('oz-router', JSON.stringify(routeName))
    }
  })

  onLoad()
})

</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.v-avatar-logo {
  height: 100%;
  margin: 2% auto;
}

.app-main {
  background: var(--pikini-red);
}

.app-main .http-error {
  margin: 45px auto 0 auto;
  width: 500px;
  color: #fff;
  text-align: center;
}
</style>
