import {
  defineStore
} from "pinia";


export const useAuthStore = defineStore("auth", {
  
  state: () => ({
    user: {
      name: "Pikini Admin",
      picture: "@/assets/avatars/userTest-avatar.jpg"
    },
    token: null
  }),

  actions: {
    updateToken(token) {
      this.token = token;
    },
    updateAll(data) {
      this.expire = data.expires_in??this.expire;
      this.refresh = data.refresh_token??this.refresh;
      this.token = data.access_token??this.refresh;
    },
    updateAuth(user) {
      this.user = user;
    }
  },

  getters: {
    getAuth: (state) => state.user,
    getToken: (state) => state.token,
    getRefreshToken: (state) => state.refresh,
    getLoading: state => state.loading
  },
});