
export default {
  home: {
    icon: "mdi-home",
    title: "Accueil",
    value: "auth:home",
    username: "",
    is_in_main: true,
    usernail: "",
    is_profile: false,
    is_logout: false,
  },
  movies: {
    icon: "mdi-filmstrip",
    title: "Mes films",
    value: "auth:movies",
    username: "",
    is_in_main: true,
    usernail: "",
    is_profile: false,
    is_logout: false,
  },
  series: {
    icon: "mdi-video-vintage",
    title: "Mes séries",
    value: "auth:series",
    username: "",
    is_in_main: true,
    usernail: "",
    is_profile: false,
    is_logout: false,
  },
  // medias: {
  //   icon: "mdi-folder-arrow-up",
  //   title: "Mes vidéos téléchargées",
  //   value: "auth:media",
  //   username: "",
  //   is_in_main: true,
  //   usernail: "",
  //   is_profile: false,
  //   is_logout: false,
  // },
  actors: {
    icon: "mdi-account-group",
    title: "Mes acteurs",
    value: "auth:actors",
    username: "",
    is_in_main: true,
    usernail: "",
    is_profile: false,
    is_logout: false,
  },
  stats: {
    icon: "mdi-finance",
    title: "Statistiques",
    value: "auth:stats",
    username: "",
    is_in_main: true,
    usernail: "",
    is_profile: false,
    is_logout: false,
  },
  profile: {
    icon: "mdi-account",
    title: "Profil Utilisateur",
    value: "auth:profile",
    username: "Administration",
    is_in_main: false,
    usernail: "agenceinov@yahoo.fr",
    is_profile: true,
    is_logout: false,
  },
  logout: {
    icon: "mdi-location-exit",
    title: "Se déconnecter",
    value: "logout",
    username: "",
    is_in_main: false,
    usernail: "",
    is_profile: false,
    is_logout: true,
  },
};