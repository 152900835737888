import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios';

import {
  commandLogin,
  commandRefreshToken,
  commandRegister,
  commandOnInit
} from '../services/command'

import { queryDisconnect } from '../services/queries'
import { useAuthStore } from '../store'
import { useGlobalStore } from '@/store'
import { pipeline } from '@/utils'


// ENV variables
const appVersion = process.env.APP_UPDATE_VERSION;
const appPlatform = process.env.APP_PLATFORM;

const loginData = reactive({
  email: '',
  password: '',
  stayConnected: true,
  emailError: '',
  passwordError: '',
  error: '',
  user: null,
  isLoading: false
})

const registerData = reactive({
  name: '',
  nameError: '',
  phone: '',
  phoneError: '',
  password: '',
  passwordError: '',
  confirmPassword: '',
  confirmPasswordError: ''
})

const useHook = () => {
  const store = useAuthStore()
  const global = useGlobalStore()
  const $router = useRouter()

  const onLoginDiffuser = async (token = null) => {
    console.log('start running logging diffuer')
    let _token = token ?? loginData.user.token;
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/space/diffuser/login`,
      {diff_id: _token},
      {
        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${_token}`, 'X-app-version' : appVersion, 'X-app-platform' : appPlatform  },
      }
    );


    if (response.status == 200) {
      const { data } = response.data
      loginData.user.setDiffuser(data)
      store.updateToken(_token)
      store.updateAuth(loginData.user)

      return true
    } else {
      return false
    }
    
  } // [onLoginDiffuser]

  const onLogin = async () => {
    try {
      loginData.error = ''
      const { api } = commandLogin({
        phone: loginData.email,
        password: loginData.password
      })

      const response = await api()

      const {
        data: { data },
        status
      } = response

      if (status == 200) {
        
        await onSaveToken({
          expire: data.expires_in,
          token: data.access_token,
          refresh: data.refresh_token
        })
        store.updateAll(data);
        return true
      } else {
        loginData.error = 'Mot de passe ou login incorrect'
        return false
      }
    } catch (e) {
      // console.log(e)
    }
  } //[onLogin]

  const onRefreshToken = async () => {
    const location = JSON.parse(window.localStorage.getItem('pikini'))
    if (location) {
      const refresh_token = location.refresh;
      try {
        loginData.error = ''
        const { api } = commandRefreshToken({
          refresh_token: refresh_token
        })

        const response = await api()

        const {
          data: { data },
          status
        } = response

        if (status == 200) {
          store.updateToken(data.token)

          await onSaveToken({
            expire: data.expires_in,
            token: data.access_token,
            refresh: data.refresh_token
          });

          return data;
        } else {
          loginData.error = 'Eche de rafraichissement du token'
          return false
        }
      } catch (e) {
        // console.log(e)
      }
    }
    return false;
  } //[onRefreshToken]

  const onSaveToken = ({ expire, token, refresh}) => {
    window.localStorage.setItem(
      'pikini',
      JSON.stringify({
        expire: expire,
        token: token,
        refresh: refresh
      })
    )
  } //[onSaveToken]

  const onRegister = data => {
    global.updateLoader(true)
    commandRegister(data)
      .then(response => console.log(response))
      .catch(error => console.log(error))
      .finally(() => global.updateLoader(false))
  } //[onRegister]

  const onInit = async () => {
    if (!JSON.parse(window.localStorage.getItem('pikini'))) {
      return await false
    }
    
    const { api, callback } = commandOnInit()
    const response = await api()

    if (response.status == 200) {
      loginData.user = callback(response.data)
      return true
    } else {
      return false
    }
  } //[onInit]

  const onLogOut = () => {
    global.updateLoader(true)
    global.resetMedia()
    queryDisconnect({})
      .then(() => {
        global.resetMedia()
        store.updateToken(null)
        store.updateAuth(null)
        window.localStorage.removeItem('pikini')
        window.localStorage.removeItem('pikini-router')
      })
      .catch(error => console.log(error))
      .finally(() => global.updateLoader(false))
  } //[onLogOut]

  const onSigin = async () => {
    try {
      const { add, run } = pipeline()

      loginData.isLoading = true

      add(onLogin)
      add(onInit)
      add(onLoginDiffuser)

      const status = await run()
      loginData.isLoading = false

      if (status) {
        const routeName = JSON.parse(window.localStorage.getItem('pikini-router'))
        $router.push({ name: routeName ? routeName : 'auth:home' })
      }
    } catch (e) {
      // console.log(e)
      loginData.isLoading = false

      return false
    }
  }

  const onLoad = async () => {
    try {
      const { add, run } = pipeline()
      global.updateLoader(true)
      add(onInit)
      add(onLoginDiffuser)
      await run()
      global.updateLoader(false)
    } catch (e) {
      global.updateLoader(false)
      return false
    }
  }

  return {
    onRefreshToken,
    onRegister,
    onLogOut,
    onSigin,
    onLoad,
    loginData,
    registerData,
    onLoginDiffuser

  }
}

export default useHook
