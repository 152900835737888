<template>
  <v-app-bar flat color="#e30119" class="v-header w-100">
    <v-container>
      <v-row>
        <v-col align-self="center" class="v-col-2">
          <div class="v-logo">
            <img :src="appLogo" alt="">
            <h1>Pikini - Back Office</h1>
          </div>
        </v-col>
        <v-col align-self="center" class="v-col-8">
          <h3 class="text-center"> <v-icon :icon="icon" size="24px"></v-icon> {{ props.title }}</h3>
        </v-col>
        <v-col align-self="center" class="v-col-2">
          <v-btn v-if="logged"><v-icon icon="mdi-upload"></v-icon><span>Téléverser</span></v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script setup>
import { defineProps } from 'vue';

const fullURL = window.location.href;
const url = new URL(fullURL);
const protocol = url.protocol;
const host = url.host;

const fullHost = protocol + '//' + host;

const appLogo =  fullHost + '/assets/logo.png'

const props = defineProps({ 
  title: { type: String },  
  icon: { type: String },  
  logged: { type: Boolean }
  });
</script>
<style>
.v-logo {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  padding: 5px 5px;
  padding-left: 10px;
}

.v-logo img {
  position: relative;
  display: inline;
  height: 100%;
  width: auto;
  padding: 5px 5px;
}

.v-logo h1 {
  position: relative;
  display: inline;
  color: #ffffff;
  font-size: 1.5em;
  font-weight: 700;
}

.v-header h3,
.v-header .v-btn {
  color: #ffffff;
}
</style>